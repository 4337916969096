import React, {useEffect, useState} from 'react';
import {Box, Button, Chip, Stack, TextField, Typography} from "@mui/material";
import {useUserStore} from "../../global/store/UserStore";
import {useNavigate} from "react-router-dom";
import '../styles/AdminPage.scss';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {toast} from "react-toastify";
import PageInsideHeader from "../../global/components/PageInsideHeader";
import LoadingButton from "../../global/components/LoadingButton";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiFindUsersByAdmin} from "../actions/api";
import {UserInfoType} from "../../global/actions/types.api";

const AdminFindUser = () => {

  const navigate = useNavigate();
  const isAdmin = useUserStore((state) => state.isAdmin);

  const [login, setLogin] = useState<string>('');
  const [findUserResponse, findUserLoading, findUserError, requestFindUser] = useLoadingHook<UserInfoType[]>(apiFindUsersByAdmin)

  useEffect(() => {
    if (!isAdmin) {
      toast.error('У вас нет доступа к админ-панели');
      navigate('/');
    }
  }, []);

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    requestFindUser(login);
  };

  useEffect(() => {
    if (findUserError) {
      toast.error(findUserError?.response?.data?.message ?? 'Неопознанная ошибка');
    }
  }, [findUserError]);

  return (
    <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <PageHeader />
      <Box sx={{...wrapperSx, pb: 3}} >
        <PageInsideHeader text={"Поиск пользователя по номеру телефона"}/>

        <Box
          sx={{
            mt: 2,
            borderRadius: '16px',
            p: 2,
            bgcolor: '#fff',
            gap: '16px'
          }}
        >
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
            onSubmit={handleSearch}
          >
            <TextField
              value={login}
              label={'Поиск'}
              helperText={'ИИН / Номер телефона / Электронная почта'}
              sx={{
                maxWidth: '300px',
              }}
              onChange={(e) => {setLogin(e.target.value)}}
            />

            <LoadingButton
              disabled={login.length === 0}
              sx={{
                maxWidth: '300px',
              }}
              type={'submit'}
            >
              Найти
            </LoadingButton>
          </form>

          <Stack
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={'16px'}
            sx={{
              mt: 2
            }}
          >
            {findUserResponse?.length === 0 && (
              <Typography>
                Нет пользователей с данной информацией
              </Typography>
            )}
            {findUserResponse?.map((user) => {
              return (
                <Stack
                  flexDirection={'column'}
                  gap={1}
                  sx={{
                    p: 2,
                    border: '1px solid gray',
                    borderRadius: '16px',
                    minWidth: '300px'
                  }}
                >
                  <Typography
                    fontSize={'12px'}
                    color={'gray'}
                  >
                    Пользователь #{user?.id}
                  </Typography>
                  <Typography>
                    ФИО: {user?.last_name} {user?.first_name} {user?.patronymic}
                  </Typography>
                  <Typography>
                    Номер телефона: {user?.phone ? user?.phone : 'Н/Д'}
                  </Typography>
                  <Typography>
                    Электронная почта: {user?.email ? user?.email : 'Н/Д'}
                  </Typography>
                  <Typography>
                    Роли:
                  </Typography>
                  <Stack
                    flexDirection={'row'}
                    gap={1}
                  >
                    {user?.roles && user?.roles?.length > 0 && user?.roles?.map((role) => {
                      return <Chip label={role.role === 'admin' ? 'Администратор' : role.role === 'observer' ? 'Наблюдатель' : role.role === 'manager' ? 'Менеджер' : role.role === 'student' ? 'Студент' : 'Неопознанная роль'} />
                    })}
                  </Stack>

                  <Button onClick={() => {navigate(`/admin/change-user-info/${user.id}`)}}>
                    Изменить данные пользователя
                  </Button>
                </Stack>
              )
            })}
          </Stack>
        </Box>

      </Box>
    </Box>
  );
};

export default AdminFindUser;