import React, {useEffect, useState} from 'react';
import {Box, Button, Chip, CircularProgress, Divider, InputBase, Stack, TextField, Typography} from '@mui/material';

import '../styles/AuthPage.scss';
import {apiRequestOtp, login, loginByOtp} from "../actions/api";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {Link, useNavigate} from "react-router-dom";
import {useUserStore} from "../../global/store/UserStore";
import Cookies from "js-cookie";
import {IRole} from "../dto/AuthDto";
import PageHeader from "../../global/components/PageHeader";
import {Logout} from "@mui/icons-material";
import {setTokens} from "../../global/actions/main.api";
import { useTranslation } from 'react-i18next';
import LanguageSelector from "../../global/components/LanguageSelector";
import LoadingButton from "../../global/components/LoadingButton";
import {toast} from "react-toastify";
import useLoadingHook from "../../global/hooks/UseLoadingHook";


interface IOtpAuth {
  phone: string;
  password: string;
}

const OtpAuthPage = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const userInfo = useUserStore((state) => state);
  const [authForm, setAuthForm] = useState<IOtpAuth>({
    phone: '',
    password: ''
  });
  const [otpVisible, setOtpVisible] = useState<boolean>(false);

  const setUserInfo = useUserStore((state) => state.setUserInfo);
  const setIsObserver = useUserStore((state) => state.setIsObserver);
  const setIsAdmin = useUserStore((state) => state.setIsAdmin);
  const setIsSuperAdmin = useUserStore((state) => state.setIsSuperAdmin);
  const setIsStudent = useUserStore((state) => state.setIsStudent);

  const [otpResponse, otpLoading, otpError, requestOtp] = useLoadingHook(apiRequestOtp);
  const [authenticateResponse, authenticateLoading, authenticateError, authenticate] = useLoadingHook(loginByOtp);

  useEffect(() => {
    if (userInfo.iin) {
      navigate('/auth');
    }
  }, []);

  const validatePhoneNumber = (value: string) => {
    const regex = /^\+7\d{10}$/;
    return regex.test(value);
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const name = e.target.name;
    setAuthForm((prev) => {
      return {
        ...prev,
        [name]: value,
      }
    });
  };

  const handleRequestOtp = () => {
    if (!authForm.phone) {
      toast.error('Пожалуйста введите мобильный номер телефона.')
      return;
    }

    if (!validatePhoneNumber(authForm.phone)) {
      toast.error('Номер телефона не соответствует формату: +7**********')
      return;
    }

    requestOtp(authForm.phone);
  };

  useEffect(() => {
    if (otpError) {
      toast.error(otpError?.response?.data?.message ?? 'Произошла непредвиденная ошибка');
    }
  }, [otpError]);

  useEffect(() => {
    if (otpResponse) {
      toast.success('Одноразовый пароль успешно отправлен на ваш номер телефона.');
      setOtpVisible(true);
    }
  }, [otpResponse]);

  const handleAuthenticate = () => {
    if (!authForm.phone) {
      toast.error('Пожалуйста введите мобильный номер телефона.')
      return;
    }

    if (!validatePhoneNumber(authForm.phone)) {
      toast.error('Номер телефона не соответствует формату: +7**********')
      return;
    }

    if (!authForm.password) {
      toast.error('Необходимо ввести одноразовый пароль из SMS')
      return;
    }

    if (authForm.password.length < 6) {
      toast.error('Одноразовый пароль должен быть длиной из 6 цифр')
      return;
    }

    authenticate(authForm);
  }

  const roleNavigate = (roles: IRole[]) => {
    let isAdmin = false;
    let isStudent = false;
    let isObserver = false;

    for (let i = 0; i < roles.length; i++) {
      const role = roles[i];
      if (role.role === 'admin') {
        setIsAdmin(true);
        isAdmin = true;
      } else if (role.role === 'student') {
        setIsStudent(true);
        isStudent = true;
      } else if (role.role === 'superadmin') {
        setIsSuperAdmin(true);
      } else if (role.role === 'observer') {
        setIsObserver(true);
        isObserver = true;
      } else {
        setIsAdmin(false);
        isAdmin = false;
      }
    }

    if (!isAdmin) {
      navigate('/');
    } else if (!isStudent) {
      navigate('/');
    }

    if (isAdmin || isObserver) {
      navigate('/');
    }
  }

  useEffect(() => {
    if (authenticateError) {
      toast.error(authenticateError?.response?.data?.message ?? 'Произошла непредвиденная ошибка');
    }
  }, [authenticateError]);

  useEffect(() => {
    if (authenticateResponse) {
      toast.success('Вы успешно вошли в свой аккаунт');
      const resData = authenticateResponse;

      const {accessToken, refreshToken} = resData;

      if (accessToken && refreshToken) {
        setTokens({accessToken, refreshToken})
        setUserInfo(resData);
        roleNavigate(resData.roles);
      } else {
        toast.error('Не удалось получить JwT-токены авторизации. Обратитесь в службу технической поддержки');
        return;
      }
    }
  }, [authenticateResponse]);

  return (
    <Box className={'auth App'} id={'auth'} sx={{p: 5}} flexDirection={'column'} alignItems={'center'}>

      <PageHeader authBar={true}/>

      <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <Typography sx={{mt: 3}} variant={'h5'}><b>{t('auth.auth-page.header')}</b></Typography>
        <Typography>{t('auth.auth-page.subheader')}</Typography>
      </Stack>

      {!userInfo.iin && (
        <Stack
          flexDirection={'column'}
          sx={{
            width: '434px',
            p: '32px',
            background: '#FFFFFF',
            borderRadius: '16px',
            mt: '81px'
          }}
        >
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant={'h5'} >Вход по одноразовому паролю (SMS)</Typography>
          </Stack>

          <Stack flexDirection={'column'} gap={'16px'} sx={{mt: '24px', mb: '24px'}}>
            <TextField
              type={'text'}
              placeholder={'Номер мобильного телефона'}
              value={authForm.phone}
              name={'phone'}
              onChange={handleInputChange}
              helperText={'Формат: +7**********'}
            />

            {!otpVisible && <LoadingButton
              variant={'contained'}
              onClick={handleRequestOtp}
              loading={otpLoading}
            >
              Запросить одноразовый пароль
            </LoadingButton>}

            {otpVisible && <TextField
              type={'password'}
              placeholder={t('auth.auth-page.password')}
              value={authForm.password}
              name={'password'}
              onChange={handleInputChange}
            />}

            {otpVisible && <LoadingButton
                variant={'contained'}
                onClick={handleAuthenticate}
                loading={otpLoading}
            >
                Войти
            </LoadingButton>}

            <Button
              onClick={() => {navigate('/auth')}}
              style={{
                textAlign: 'center',
                marginTop: '20px',
              }}
            >
              Войти обычным способом
            </Button>
            <Chip
              label={otpVisible ? 'У меня нет одноразового пароля' : 'У меня уже есть одноразовый пароль'}
              onClick={() => {setOtpVisible((prev) => !prev)}}
            />
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default OtpAuthPage;