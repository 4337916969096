import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";

export const getCreatedAppeals = () => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/created`);
}

export const getApprovedAppeals = () => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/approved`);
}

export const getClosedAppeals = () => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/closed`);
}


export const getDeniedAppeals = () => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/denied`);
}

export const getAppealById = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/register-appeals/${id}`);
}

export const approveAppealApi = (id: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/approve/${id}`, {});
}

export const deleteAppeal = (id: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/delete/${id}`, {})
}

export const denyAppeal = (id: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/deny/${id}`, {});
}

export const resendEmail = (email: any) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/resend`, email);
}

export const changeEmailByAdmin = (email: string, appealId: number) => {
    return axiosInstance.post(`${BACKEND_URL}/register-appeals/change-email/${appealId}`, {email});
}

export const apiGetFeatureToggles = () => {
    return axiosInstance.get(`${BACKEND_URL}/feature-toggles/`);
}

export const apiChangeSchoolFeatureEnable = (changeFeatureEnableDto: ChangeSchoolFeatureEnableDto) => {
    return axiosInstance.post(`${BACKEND_URL}/feature-toggles/school/change-enabled`, changeFeatureEnableDto);
}

export const apiChangeFeatureEnable = (changeFeatureEnableDto: ChangeFeatureEnableDto) => {
    return axiosInstance.post(`${BACKEND_URL}/feature-toggles/change-enabled`, changeFeatureEnableDto);
}

export const apiGetSchoolsWithFeatureToggles = () => {
    return axiosInstance.get(`${BACKEND_URL}/schools/feature-toggles`)
}

export const apiGetSchoolFeatureToggle = (dto: ChangeSchoolFeatureEnableDto) => {
    return axiosInstance.post(`${BACKEND_URL}/feature-toggles/school`, dto)
}

// ----------------- NEWS

export const apiAddNews = (dto: NewsAddDto) => {
    return axiosInstance.post(`${BACKEND_URL}/news/new`, dto);
}

export interface ChangeFeatureEnableDto {
    module_name: string;
    is_enabled: boolean;
}

export interface ChangeSchoolFeatureEnableDto extends ChangeFeatureEnableDto {
    school_id: number;
}

export interface NewsAddDto {
    title_ru: string;
    title_kz: string;
    content_ru: string;
    content_kz: string;
    thumbnail?: string;
    user_id: number;
}

// ------------------ USERS

export const apiFindUsersByAdmin = (login: string) => {
    return axiosInstance.get(`${BACKEND_URL}/users/admin/${login}`);
}

export const apiGetUserById = (id: number) => {
    return axiosInstance.get(`${BACKEND_URL}/users/${id}`);
}