import React from 'react';
import { Chip, Box, Typography, Grid } from '@mui/material';
import {submitGradeByStudentId} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import {useUserStore} from "../../../global/store/UserStore";
import {toast} from "react-toastify";

interface GradesType {
    id: number;
    student_id: number;
    maintenance: number | null;
    rules: number | null;
    practice: number | null;
}

interface GradesComponentProps {
    schoolId: number;
    grades: null | GradesType;
    addLoadingId: (rowId: number) => void;
    removeLoadingId: (rowId: number) => void;
    groupInfo: any;
    fetchGroupinfo: () => Promise<void>;
    row: any;
}

const gradesOptions = [2, 3, 4, 5];

const GradesComponent: React.FC<GradesComponentProps> = ({ schoolId, grades, addLoadingId, removeLoadingId, row, fetchGroupinfo, groupInfo }) => {

    const userStore = useUserStore((state) => state);

    const submitGrade = async (subject: string, grade: number) => {
        try {
            addLoadingId(row.id);
            const validSubjects = ['maintenance', 'rules', 'practice'];

            if (!validSubjects.includes(subject)) {
                throw new Error('Выбран несуществующий предмет. Обратитесь в службу поддержки');
            }

            await submitGradeByStudentId({
                student_id: row.id,
                [subject]: grade,
            }, schoolId);

            toast.success('Изменение оценки произошло успешно.');
        } catch (err: any) {
            toast.error(err.response?.data.message || 'Произошла ошибка при выставлении оценки');
        } finally {
            await fetchGroupinfo();
            removeLoadingId(row.id);
        }
    };

    const handleGradeClick = (subject: string, grade: number) => {
        submitGrade(subject, grade);
    };

    return (
        <Box py={2} sx={{width: '100%'}}>
            <Grid container spacing={2} alignItems="center" sx={{width: '100%'}}>
                <Grid item xs={6} sm={6} md={6}>
                    <Typography variant={'subtitle2'}>Устройство и техническое обслуживание:</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} display={'flex'} flexDirection={'row'} gap={1}>
                    {gradesOptions.map((grade) => (
                        <Chip
                            key={grade}
                            label={grade}
                            clickable={grades?.maintenance !== grade}
                            variant={"filled"}
                            color={grades?.maintenance === grade ? "primary" : "default"}
                            disabled={grades?.maintenance === grade || userStore.isObserver}
                            onClick={() => {
                                handleGradeClick('maintenance', grade);
                            }}
                        />
                    ))}
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                    <Typography variant={'subtitle2'}>Правила и основы движения:</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} display={'flex'} flexDirection={'row'} gap={1}>
                    {gradesOptions.map((grade) => (
                        <Chip
                            key={grade}
                            label={grade}
                            clickable={grades?.rules !== grade}
                            variant={"filled"}
                            color={grades?.rules === grade ? "primary" : "default"}
                            disabled={grades?.rules === grade || userStore.isObserver}
                            onClick={() => {
                                handleGradeClick('rules', grade);
                            }}
                        />
                    ))}
                </Grid>

                <Grid item xs={6} sm={6} md={6}>
                    <Typography variant={'subtitle2'}>Практическое вождение:</Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6} display={'flex'} flexDirection={'row'} gap={1}>
                    {gradesOptions.map((grade) => (
                        <Chip
                            key={grade}
                            label={grade}
                            clickable={grades?.practice !== grade}
                            variant={"filled"}
                            color={grades?.practice === grade ? "primary" : "default"}
                            disabled={grades?.practice === grade || userStore.isObserver}
                            onClick={() => {
                                handleGradeClick('practice', grade);
                            }}
                        />
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
};

export default GradesComponent;
