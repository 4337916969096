import axiosInstance from "../../global/actions/main.api";
import {BACKEND_URL} from "../../global/actions/apiVariables";

export const apiGetUserOfferStatus = (userOfferVersion: string) => {
  return axiosInstance.get(`${BACKEND_URL}/users/user-offer/${userOfferVersion}`);
}

export const apiAcceptUserOffer = (userOfferVersion: string) => {
  return axiosInstance.get(`${BACKEND_URL}/users/user-offer/accept/${userOfferVersion}`);
}

export const apiConfirmGroup = (studentId: number) => {
  return axiosInstance.get(`${BACKEND_URL}/students/confirm/group/${studentId}`);
}