import React, {useEffect, useState} from 'react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox, CircularProgress,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useUserStore} from "../../global/store/UserStore";
import {
  StudentInfoType,
} from "../../global/actions/types.api";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {apiFindStudentsByIIN} from "../../GovPage/actions/api";
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {Add, Book, DirectionsCar} from "@mui/icons-material";
import TicketsUserGrid from "../../Tickets/components/TicketsUserGrid";
import {NewsSection} from "../../Landing/pages/SchoolLandingPage";
import {useTranslation} from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import {getUserTickets} from "../../Tickets/actions/api";
import {toast} from "react-toastify";
import {apiAcceptUserOffer, apiConfirmGroup, apiGetUserOfferStatus} from "../actions/api";
import LoadingButton from '../../global/components/LoadingButton';
import {formatDate} from "../../global/helpers/text";

// TODO - разделить личные кабинеты администратора, менеджеров школ и студентов по разным страницам.

function StudentMainPage() {

  const navigate = useNavigate();
  const currentUser = useUserStore((state) => state);

  const {t} = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  // const [currentStudentInfo, setCurrentStudentInfo] = useState<StudentInfoType | null>(null);
  // const [startExamModalOpen, setStartExamModalOpen] = useState<boolean>(false);
  // const [startTrialExamModalOpen, setStartTrialExamModalOpen] = useState<boolean>(false);

  const [studentsInfo, studentsInfoLoading, studentsInfoError, getStudentsInfo] = useLoadingHook<StudentInfoType[]>(apiFindStudentsByIIN);
  const [ticketsList, ticketsListLoading, ticketsListError, requestTicketsList] = useLoadingHook(getUserTickets);
  const [userOfferStatus, userOfferStatusLoading, userOfferStatusError, requestUserOfferStatus] = useLoadingHook(apiGetUserOfferStatus);
  const [acceptUserOfferResponse, acceptUserOfferLoading, acceptUserOfferError, requestAcceptUserOffer] = useLoadingHook(apiAcceptUserOffer);
  const [confirmGroupResponse, confirmGroupLoading, confirmGroupError, requestConfirmGroup, resetConfirmGroup] = useLoadingHook(apiConfirmGroup);

  const [hasGeneratedPassword, setHasGeneratedPassword] = useState<boolean>(false);
  const [userOfferAccepted, setUserOfferAccepted] = useState<boolean>(false);
  const [currentStudentIndex, setCurrentStudentIndex] = useState(0);
  const [needConfirmationGroups, setNeedConfirmationGroups] = useState<StudentInfoType[]>([]);

  const currentUserOffer = 'StudentOffer1.0';

  const goToTicket = (ticketId: number) => {
    navigate(`/tickets/${ticketId}`);
  }

  useEffect(() => {
    getStudentsInfo(currentUser.iin);
    requestTicketsList();
    requestUserOfferStatus(currentUserOffer)
  }, []);

  useEffect(() => {
    if (studentsInfo) {
      const needConfirmationArray : StudentInfoType[] = [];

      studentsInfo.forEach((studentInfo) => {
        if (studentInfo.generated_user_password) {
          setHasGeneratedPassword(true);
        }

        if (!studentInfo.student_confirmation?.confirmed) {
          needConfirmationArray.push(studentInfo);
        }
      })

      setNeedConfirmationGroups(needConfirmationArray);
    }
  }, [studentsInfo]);

  useEffect(() => {
    if (studentsInfoError) {
      toast.error(studentsInfoError?.response?.data?.message ?? 'Непредвиденная ошибка')
    }
  }, [studentsInfoError]);

  useEffect(() => {
    if (userOfferStatus) {
      setUserOfferAccepted(userOfferStatus.accepted);
    }
  }, [userOfferStatus]);

  useEffect(() => {
    if (acceptUserOfferResponse) {
      setUserOfferAccepted(true);
    }
  }, [acceptUserOfferResponse]);

  useEffect(() => {
    if (acceptUserOfferError) {
      toast.error(acceptUserOfferError?.response?.data?.message ?? 'Непредвиденная ошибка')
    }
  }, [acceptUserOfferError]);

  useEffect(() => {
    if (confirmGroupResponse) {
      getStudentsInfo(currentUser.iin);
      resetConfirmGroup();
    }
  }, [confirmGroupResponse]);

  useEffect(() => {
    if (confirmGroupError) {
      toast.error(confirmGroupError?.response?.data?.message ?? 'Непредвиденная ошибка')
    }
  }, [confirmGroupError]);

  const handleStudentIndexChange = (type: string) => {
    if (type === 'next') {
      if (studentsInfo?.length === currentStudentIndex + 1) {
        setCurrentStudentIndex(0);
      } else {
        setCurrentStudentIndex((prev) => prev + 1);
      }
    }

    if (type === 'previous') {
      if (currentStudentIndex === 0) {
        setCurrentStudentIndex(studentsInfo?.length ? studentsInfo?.length - 1 : 0);
      } else {
        setCurrentStudentIndex((prev) => prev - 1)
      }
    }
  }

  return (
    <Box
         sx={{
           height: '100%',
           display: 'flex',
           flexDirection: 'column',
           background: '#f4f7fe'
         }}
    >
      <PageHeader />

      <Box sx={{...wrapperSx, px: isMobile ? '10px' : '60px', pb: '60px'}}>

        <Typography
          fontSize={'24px'}
          lineHeight={'120%'}
          color={'#707EAE'}
          sx={{
            mt: '20px'
          }}
        >
          {t('main-pages.manager.hello')} {currentUser.first_name},
        </Typography>
        <Typography
          fontSize={isMobile ? '32px' : '40px'}
          lineHeight={'100%'}
          color={'#2b3674'}
          fontWeight={'bold'}
          sx={{
            mt: '11px'
          }}
        >
          {t('main-pages.manager.welcome')}
        </Typography>

        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'center' : 'flex-start'}
          sx={{
            mt: '46px'
          }}
          gap={'20px'}
        >
          <Stack
            flexDirection={'column'}
            alignItems={'center'}
            width={isMobile ? 'auto' : '427px'}
            height={'499px'}
            sx={{
              background: '#1e9af3',
              borderRadius: '20px',
              py: '40px',
              px: isMobile ? '10px': '0'
            }}
          >
            <Stack>
              <img src={'/images/new-design/avatar.png'} alt={''} />
            </Stack>
            <Typography
              fontSize={'26px'}
              lineHeight={'100%'}
              color={'#fff'}
              sx={{
                mt: '18px'
              }}
            >
              {currentUser?.last_name} {currentUser?.first_name}
            </Typography>
            <Typography
              fontSize={'18px'}
              lineHeight={'100%'}
              color={'rgba(255,255,255,0.5)'}
              sx={{
                mt: '14px'
              }}
            >
              {currentUser?.iin}
            </Typography>
            <Typography
              fontSize={'24px'}
              lineHeight={'100%'}
              color={'#fff'}
              sx={{
                mt: '42px'
              }}
            >
              {currentUser.phone ? currentUser.phone : t('main-pages.manager.no-phone')}
            </Typography>
            <Typography
              fontSize={'18px'}
              lineHeight={'100%'}
              color={'rgba(255,255,255,0.5)'}
              sx={{
                mt: '10px'
              }}
            >
              {currentUser?.email}
            </Typography>
            <Button
              variant={'outlined'}
              onClick={() => {navigate(`/change-password/${currentUser.id}`)}}
              color={'success'}
              sx={{
                width: '347px',
                height: '54px',
                border: '0px !important',
                mt: 'auto',
                background: 'rgba(255,255,255,0.16)',
                textTransform: 'none',
                color: '#fff',
                fontWeight: 'normal',
                ':hover': {
                  background: 'rgba(255,255,255,0.36)'
                }
              }}
            >
              {t('main-pages.manager.change-password')}
            </Button>
          </Stack>

          <Stack
            flexDirection={'column'}
            sx={{
              width: isMobile ? '100%' : '873px',
              height: isMobile ? 'auto' : '499px',
              p: isMobile ? '10px' : '40px',
              background: '#fff',
              borderRadius: '20px'
            }}
            gap={'10px'}
          >
            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              gap={'41px'}
            >
              <Stack
                flexDirection={'row'}
                sx={{
                  width: isMobile ? 'auto': '387px',
                  minHeight: '147px',
                  background: '#f4f7fe',
                  borderRadius: '20px',
                  p: '24px 16px 24px 24px'
                }}
              >
                <Stack
                  flexDirection={'column'}
                >
                  <Typography
                    fontSize={'16px'}
                    lineHeight={'100%'}
                    color={'#1e9af3'}
                  >
                    ID {studentsInfo?.[currentStudentIndex].group?.school?.id}
                  </Typography>

                  <Stack
                    flexDirection={'column'}
                    sx={{
                      mt: 'auto'
                    }}
                    gap={'10px'}
                  >
                    <Typography
                      fontSize={'16px'}
                      lineHeight={'100%'}
                      color={'rgba(5,48,79,0.6)'}
                    >
                      {t('main-pages.manager.organization')}
                    </Typography>
                    <Typography
                      fontSize={'22px'}
                      lineHeight={'100%'}
                      color={'#05304f'}
                    >
                      {studentsInfo?.[currentStudentIndex].group?.school?.title}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  flexDirection={'column'}
                  sx={{
                    ml: 'auto'
                  }}
                >
                  <Avatar
                    sx={{color: '#1E9AF3', bgcolor: '#fff', mt: '-9px', width: 58, height: 58}}
                  >
                    <DirectionsCar fontSize="medium"/>
                  </Avatar>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              gap={'41px'}
            >
              <Stack
                flexDirection={'row'}
                sx={{
                  width: isMobile ? 'auto': '387px',
                  minHeight: '130px',
                  background: '#f4f7fe',
                  borderRadius: '20px',
                  p: '24px 16px 24px 24px'
                }}
              >
                <Stack
                  flexDirection={'column'}
                >
                  <Stack
                    flexDirection={'column'}
                    gap={'10px'}
                  >
                    <Typography
                      fontSize={'16px'}
                      lineHeight={'100%'}
                      color={'rgba(5,48,79,0.6)'}
                    >
                      Категория обучения
                    </Typography>
                    <Typography
                      fontSize={'22px'}
                      lineHeight={'100%'}
                      color={'#05304f'}
                    >
                      {studentsInfo?.[currentStudentIndex].group?.category_id}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  flexDirection={'column'}
                  sx={{
                    ml: 'auto'
                  }}
                >
                  <Avatar
                    sx={{color: '#1E9AF3', bgcolor: '#fff', mt: '-9px', width: 58, height: 58}}
                  >
                    <Book fontSize="medium"/>
                  </Avatar>
                </Stack>
              </Stack>

              <Stack
                flexDirection={'row'}
                sx={{
                  width: isMobile ? 'auto': '300px',
                  minHeight: '130px',
                  background: '#f4f7fe',
                  borderRadius: '20px',
                  p: '24px 16px 24px 24px'
                }}
              >
                <Stack
                  flexDirection={'column'}
                >
                  <Stack
                    flexDirection={'column'}
                    gap={'10px'}
                  >
                    <Typography
                      fontSize={'16px'}
                      lineHeight={'100%'}
                      color={'rgba(5,48,79,0.6)'}
                    >
                      Ваша учебная группа:
                    </Typography>
                    <Typography
                      fontSize={'22px'}
                      lineHeight={'100%'}
                      color={'#05304f'}
                    >
                      {studentsInfo?.[currentStudentIndex].group?.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              flexDirection={'row'}
              gap={'10px'}
              sx={{
                height: "fit-content",
                ml: "auto",
              }}
            >
              <Button
                sx={{
                  width: 'fit-content',
                  height: 'fit-content',
                  borderRadius: '16px',
                  minWidth: 'fit-content',
                  p: 0
                }}
                onClick={() => {handleStudentIndexChange('previous')}}
              >
                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="52" height="52" rx="16" fill="#F4F7FE"/>
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M23.9141 32L17.9998 26L23.9141 20L26.4998 20L21.8283 25L33.4141 25L33.4141 27L21.8283 27L26.4998 32L23.9141 32Z"
                        fill="#05304F"/>
                </svg>
              </Button>
              <Button
                sx={{
                  width: 'fit-content',
                  height: 'fit-content',
                  borderRadius: '16px',
                  minWidth: 'fit-content',
                  p: 0
                }}
                onClick={() => {handleStudentIndexChange('next')}}
              >
                <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="52" y="52" width="52" height="52" rx="16" transform="rotate(-180 52 52)" fill="#F4F7FE"/>
                  <path fillRule="evenodd" clipRule="evenodd"
                        d="M27.5 20L33.4142 26L27.5 32L24.9143 32L29.5858 27L18 27L18 25L29.5858 25L24.9143 20L27.5 20Z"
                        fill="#05304F"/>
                </svg>
              </Button>
            </Stack>

            <Button
              variant={'outlined'}
              sx={{
                color: '#000',
                textTransform: 'none'
              }}
              disabled={true}
              onClick={() => {navigate(`/schools/}`)}}
            >
              {t('main-pages.manager.details')}
            </Button>
          </Stack>
        </Stack>

        {/* Группы, требующие подтверждения */}
        <Stack
          flexDirection={'column'}
          sx={{
            background: '#fff',
            borderRadius: '20px',
            mt: '20px'
          }}
        >
          <Stack
            flexDirection={'column'}
            sx={{
              mt: '47px',
              px: '40px',
              mb: '40px'
            }}
          >
            <Typography
              fontSize={'30px'}
              lineHeight={'100%'}
              color={'#05304F'}
            >
              Подтверждение обучения
            </Typography>

            {!userOfferAccepted && !userOfferStatusLoading && <Stack
                sx={{
                  width: 'fit-content',
                  mt: '20px'
                }}
            >
                <Alert color={'warning'}>
                    <Typography
                        fontSize={'12px'}
                    >
                        Для подтверждения обучения в учебных группах - необходимо ознакомиться и согласиться с условиями использования системы Avtomektep.KZ - ниже на странице личного кабинета.
                    </Typography>
                </Alert>
            </Stack>}

            {needConfirmationGroups.length === 0 && (
              <Alert
                sx={{
                  mt: '20px'
                }}
              >
                <Typography
                  fontSize={'12px'}
                >
                  Вы успешно подтвердили обучение в своих учебных группах. Спасибо!
                </Typography>
              </Alert>
            )}

            {confirmGroupLoading && <CircularProgress />}

            {needConfirmationGroups.length > 0 && !confirmGroupLoading && (
              <Stack
                flexDirection={'row'}
                flexWrap={'wrap'}
                sx={{
                  mt: '20px'
                }}
                gap={'20px'}
              >
                {needConfirmationGroups.map((studentInfo) => {
                  return (
                    <Stack
                      flexDirection={'column'}
                      alignItems={'center'}
                      sx={{
                        p: 2,
                        borderRadius: '16px',
                        minWidth: isMobile ? '100%' : '300px',
                        background: '#1e9af3',
                        color: '#fff'
                      }}
                      gap={1}
                    >
                      <Typography>
                        Учебный центр - "{studentInfo.group?.school?.title}"
                      </Typography>

                      <Typography>
                        Учебная группа - "{studentInfo.group?.title}"
                      </Typography>

                      <Typography>
                        Категория обучения - {studentInfo.group?.category_id}
                      </Typography>

                      <LoadingButton
                        variant={'outlined'}
                        color={'success'}
                        sx={{
                          minWidth: isMobile ? 'auto' : '347px',
                          height: '54px',
                          border: '0px !important',
                          mt: 'auto',
                          background: 'rgba(255,255,255,0.16)',
                          textTransform: 'none',
                          color: '#fff',
                          fontWeight: '600',
                          ':hover': {
                            background: 'rgba(255,255,255,0.36)'
                          }
                        }}
                        disabled={!userOfferAccepted}
                        onClick={() => {
                          requestConfirmGroup(studentInfo.id)
                        }}
                      >
                        Подтвердить обучение
                      </LoadingButton>
                    </Stack>
                  )
                })}
              </Stack>
            )}
          </Stack>
        </Stack>

        {/* Пользовательское соглашение */}
        {userOfferStatusLoading && <CircularProgress />}

        {userOfferStatus && <Stack
          flexDirection={'column'}
          sx={{
            background: '#fff',
            borderRadius: '20px',
            mt: '20px'
          }}
        >
          <Stack
            flexDirection={'column'}
            sx={{
              mt: '47px',
              px: '40px',
              mb: '40px'
            }}
          >
            <Typography
              fontSize={'30px'}
              lineHeight={'100%'}
              color={'#05304F'}
            >
              Пользовательское соглашение
            </Typography>

            {!userOfferAccepted && <Stack
              sx={{
                width: 'fit-content',
                mt: '20px'
              }}
            >
              <Alert color={'warning'}>
                  <Typography
                      fontSize={'12px'}
                  >
                      Для подтверждения обучения в учебных группах - необходимо ознакомиться и согласиться с условиями использования системы Avtomektep.KZ
                  </Typography>
              </Alert>
            </Stack>}

            {userOfferAccepted && <Stack
                sx={{
                  width: 'fit-content',
                  mt: '20px'
                }}
            >
                <Alert>
                    <Typography
                        fontSize={'12px'}
                    >
                        Вы успешно согласились с пользовательским соглашением. Спасибо!
                    </Typography>
                </Alert>
            </Stack>}

            <Stack
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={'center'}
              sx={{
                mt: '20px'
              }}
            >
              <Stack
                flexDirection={'row'}
                alignItems={'center'}
                sx={{
                  mb: isMobile ? '20px' : ''
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="60px" height="60px" viewBox="0 0 56 64" enable-background="new 0 0 56 64"
                >
                  <g>
                    <path fill="#1e9af3"
                          d="M5.1,0C2.3,0,0,2.3,0,5.1v53.8C0,61.7,2.3,64,5.1,64h45.8c2.8,0,5.1-2.3,5.1-5.1V20.3L37.1,0H5.1z"/>
                    <path fill="#1e9af3" d="M56,20.4v1H43.2c0,0-6.3-1.3-6.1-6.7c0,0,0.2,5.7,6,5.7H56z"/>
                    <path opacity="0.5" fill="#FFFFFF" enable-background="new    "
                          d="M37.1,0v14.6c0,1.7,1.1,5.8,6.1,5.8H56L37.1,0z"/>
                  </g>
                  <path fill="#FFFFFF" d="M14.9,49h-3.3v4.1c0,0.4-0.3,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7V42.9c0-0.6,0.5-1.1,1.1-1.1h3.7
                  c2.4,0,3.8,1.7,3.8,3.6C18.7,47.4,17.3,49,14.9,49z M14.8,43.1h-3.2v4.6h3.2c1.4,0,2.4-0.9,2.4-2.3C17.2,44,16.2,43.1,14.8,43.1z
                   M25.2,53.8h-3c-0.6,0-1.1-0.5-1.1-1.1v-9.8c0-0.6,0.5-1.1,1.1-1.1h3c3.7,0,6.2,2.6,6.2,6C31.4,51.2,29,53.8,25.2,53.8z M25.2,43.1
                  h-2.6v9.3h2.6c2.9,0,4.6-2.1,4.6-4.7C29.9,45.2,28.2,43.1,25.2,43.1z M41.5,43.1h-5.8V47h5.7c0.4,0,0.6,0.3,0.6,0.7
                  s-0.3,0.6-0.6,0.6h-5.7v4.8c0,0.4-0.3,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7V42.9c0-0.6,0.5-1.1,1.1-1.1h6.2c0.4,0,0.6,0.3,0.6,0.7
                  C42.2,42.8,41.9,43.1,41.5,43.1z"
                  />
                </svg>

                <a
                  href={`/${currentUserOffer}.pdf`}
                  target="_blank"
                >
                  <Typography
                      fontSize={'13px'}
                      fontWeight={600}
                      sx={{
                        ml: '5px',
                        mt: '1px'
                      }}
                  >
                      Перейти к пользовательскому соглашению
                  </Typography>
                </a>
              </Stack>

              <LoadingButton
                variant={'contained'}
                sx={{
                  px: isMobile ? '10px' : '130px',
                  ml: 'auto'
                }}
                disabled={userOfferAccepted}
                onClick={() => {
                  requestAcceptUserOffer(currentUserOffer)
                }}
                loading={false}
              >
                Согласиться
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>}

        {/* Служба поддержки */}
        <Stack
          flexDirection={'column'}
          sx={{
            background: '#fff',
            borderRadius: '20px',
            mt: '20px'
          }}
        >
          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              mt: '47px',
              px: '40px',
              mb: '40px'
            }}
          >
            <Typography
              fontSize={'30px'}
              lineHeight={'100%'}
              color={'#05304F'}
            >
              {t('main-pages.manager.your-appointments')}
            </Typography>
            <Button
              variant={'contained'}
              startIcon={<Add/>}
              sx={{
                px: '90px',
                mt: isMobile ? '20px' : '0'
              }}
              onClick={() => navigate('/tickets/new')}
            >
              {t('main-pages.manager.new-appointment')}
            </Button>
          </Stack>
          <TicketsUserGrid
            openAppeal={goToTicket}
            rows={ticketsList ? ticketsList : []}
            setTab={() => {
            }}
            tab={0}
            loading={ticketsListLoading}
            withoutTabs={true}
          />
        </Stack>

        <NewsSection
          authorized={true}
        />
      </Box>
    </Box>
  );
}

export default StudentMainPage;
